import { template as template_665a690fa5084a72a77916d3cd4c83a8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_665a690fa5084a72a77916d3cd4c83a8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
