import { template as template_19501164466048ffb051ab07b323caac } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_19501164466048ffb051ab07b323caac(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
