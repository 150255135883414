import { template as template_03a01c69a4824a1ba5721c2bf1e500c9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_03a01c69a4824a1ba5721c2bf1e500c9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
