import { template as template_e1a8e8fa5f464912b0a9e53dda46ca5a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_e1a8e8fa5f464912b0a9e53dda46ca5a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
